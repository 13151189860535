<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px;">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Caracteristicas"> </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-data-table
              :headers="headers"
              :items="items"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td>
                  <template v-if="props.item.photo">
                    <img :src="props.item.photo" :alt="props.item.value" style="width:100px" />
                    <br />
                  </template>
                  {{ props.item.value }}
                </td>
                <td>
                  <v-chip v-if="props.item.active" color="success" text-color="white" label>
                    <v-icon left>check</v-icon> Activo
                  </v-chip>
                  <v-chip v-else color="error" text-color="white" label> <v-icon left>clear</v-icon> Inactivo </v-chip>
                </td>
                <td>
                  <v-btn v-if="user.role.id !== 7" class="mx-0" color="info" small @click="openAtributo(props.item)">
                    Editar
                  </v-btn>
                  <v-btn
                    v-if="user.role.id !== 7 && props.item.type"
                    class="ml-2"
                    color="warning"
                    small
                    @click="openValues(props.item.id)"
                  >
                    Valores
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogEdit" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"> Característica </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6 class="px-2">
              <v-text-field v-model="value" label="Nombre" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6 class="px-2">
              <v-text-field v-model="info" label="Información (opcional)" outline></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6 class="px-2">
              <v-text-field v-model="description" label="Descripción (opcional)" outline></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6 class="px-2">
              <v-img v-if="closePhoto" class="mb-2" :src="closePhoto" style="width:200px; height: 200px;" />
              <FileReader
                v-if="!closePhoto"
                :name="'Foto de entrega'"
                :flat="false"
                :get-file="getFile"
                @load="closePhoto = $event"
              ></FileReader>
              <v-btn v-if="closePhoto" color="error" block @click.native="closePhoto = ''">
                Quitar Foto
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12 sm12 md12 class="px-2">
              <v-radio-group v-model="active" class="pa-0 ma-0" :mandatory="false">
                <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                <v-radio color="secondary" label="Activo" :value="true"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" flat @click.native="dialogEdit = false">Volver</v-btn>
          <v-btn color="primary" flat @click.native="sendDialog">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="user.role.id !== 7" fixed dark fab bottom right color="accent" @click="openAtributo">
      <v-icon> add </v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../../useful/title.vue'
import { GET_FEATURES, GET_FEATURES_VALUES } from '../../../config'
import FileReader from '../../useful/FileReader.vue'

export default {
  name: 'ProductFeaturesValues',
  components: { appTitle, FileReader },
  data: () => ({
    loading: false,
    items: [],
    categories: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: true,
      hidden: false
    },
    searchText: '',
    headers: [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'value'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: true,
        value: 'active'
      },
      {
        text: '',
        align: 'left',
        sortable: false
      }
    ],
    info: null,
    description: null,
    photo: null,
    value: null,
    idEdit: null,
    active: null,
    type: null,
    closePhoto: null,
    dialogEdit: false
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const res = await this.$http({
        method: 'GET',
        url: `${GET_FEATURES}/${this.$route.params.id}`
      })
      this.items = res.data[0].values
    },
    async sendDialog() {
      try {
        const formData = new FormData()
        formData.append('photo', this.photo)
        formData.append('value', this.value)
        formData.append('description', this.description)
        formData.append('info', this.info)
        formData.append('active', this.active)
        formData.append('feature_id', this.$route.params.id)

        if (this.idEdit) {
          await this.$http.post(`${GET_FEATURES_VALUES}/${this.idEdit}`, formData)
        } else {
          await this.$http.post(`${GET_FEATURES_VALUES}`, formData)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogEdit = false
        this.getInfo()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openAtributo(i) {
      if (i) {
        this.idEdit = i.id
        this.value = i.value
        this.description = i.description
        this.info = i.info
        this.active = i.active
        this.closePhoto = i.photo ? i.photo : null
      }
      this.dialogEdit = true
    },
    openValues(id) {
      this.$router.push({ name: 'productsFeaturesValues', params: { id } })
    },
    getFile(file) {
      this.photo = file
    }
  }
}
</script>
